import axios from 'axios'
import store from '../store'

export default {
  methods: {
    /**
     * Upload location image
     * @param {Number} locationId
     * @param {String} base64Image
     */
    async locationImage(locationId, base64Image) {
      let img = await this.resizeImage(base64Image)
      base64Image = img.split(',')[1]
      const response = axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/upload/image`,
        {
          Base64Image: base64Image,
          Type: 'Location',
          ItemId: locationId
        },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Upload location logo
     * @param {Number} locationId
     * @param {String} base64Image
     */
    async locationLogo(locationId, base64Image) {
      let img = await this.resizeImage(base64Image)
      base64Image = img.split(',')[1]
      const response = axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/upload/image`,
        {
          Base64Image: base64Image,
          Type: 'Logo',
          ItemId: locationId
        },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Upload event image
     * @param {Number} eventId
     * @param {String} base64Image
     */
    async eventImage(eventId, base64Image) {
      let img = await this.resizeImage(base64Image)
      base64Image = img.split(',')[1]

      const response = axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/upload/image`,
        {
          Base64Image: base64Image,
          Type: 'Event',
          ItemId: eventId
        },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    postArticleImage: async (articleId, base64Image) => {
      base64Image = base64Image.split(',')[1]

      return await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/upload/image`, {
          Base64Image: base64Image,
          Type: 'Article',
          ItemId: articleId,
      },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        })
    },

    /**
     * Resize images when needed
     * @param {String} dataUrl
     * @param {Number} maxWidth
     * @param {Number} maxHeight
     */
    async resizeImage(dataUrl, maxWidth = 1920, maxHeight = 1920) {
      return new Promise(resolve => {
        let img = new Image()
        img.onload = response => {
          if (img.width < maxWidth || img.height < maxHeight) {
            resolve(dataUrl)
          } else {
            // Do resize
            let canvas = document.createElement('canvas')

            // let maxWidth = 1920 // Max width for the image
            // let maxHeight = 1920 // Max height for the image
            let ratio = 0 // Used for aspect ratio
            let width = img.width // Current image width
            let height = img.height // Current image height

            // Check if the current width is larger than the max
            if (width > maxWidth) {
              ratio = maxWidth / width // get ratio for scaling image
              height = height * ratio // Reset height to match scaled image
              width = width * ratio // Reset width to match scaled image
            }

            // Check if current height is larger than max
            if (height > maxHeight) {
              ratio = maxHeight / height // get ratio for scaling image
              width = width * ratio // Reset width to match scaled image
            }
            canvas.width = width
            canvas.height = height

            canvas.getContext('2d').drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL('image/jpeg', 1))
          }
        }
        img.src = dataUrl
      })
    }
  }
}
