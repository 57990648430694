<template>
  <Loader v-if="isLoading" />
  <div v-else-if="articleFormData">
    <Modal
      v-if="activeModal === 'Undo'"
      :showModal="activeModal === 'Undo'"
      :modalTitle="
        this.$t('Components.Article.ArticleDetail.ModalUndoChanges.Modal_Title')
      "
      :hideCloseButton="true"
      :onClickSave="undoChanges"
      :onClickCancel="hideModal"
      :buttonLabelConfirm="$t('General.Text_YesSure')"
    >
      <template v-slot:content>
        <div
          class="has-text-centered"
          v-text="$t('General.Text_UndoChanges')"
        ></div>
      </template>
    </Modal>

    <ModalAddEditArticleSource
      v-if="activeModal === 'AddEditArticleSource'"
      :showModal="activeModal === 'AddEditArticleSource'"
      :onClickCancel="hideModal"
      :articleId="articleFormData.Id"
      :source="selectedSource"
      @updated="updatedSourceList"
    />

    <ModalDeleteArticleSource
      v-if="activeModal === 'DeleteArticleSource'"
      :showModal="activeModal === 'DeleteArticleSource'"
      :onClickCancel="hideModal"
      :articleId="articleFormData.Id"
      :source="selectedSource"
      @updated="removeItemFromSourceList"
    />

    <ModalDeleteArticle
      v-if="activeModal === 'DeleteArticle'"
      :showModal="activeModal === 'DeleteArticle'"
      :onClickCancel="hideModal"
      :article="articleFormData"
      @action="backToPreviousPage"
      @articleDeleted="backToPreviousPage"
    />

    <div class="buttons is-right">
      <a
        v-if="!isSaving"
        @click="backToArticle()"
        class="button is-ghost has-text-info-dark"
      >
        <span class="icon is-small mr-1">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </span>
        <span>
          {{ $t('Form.Control.Back') }}
        </span>
      </a>
      <a
        v-if="!isSaving"
        :class="{
          'has-text-primary-dark': hasOpenChanges,
          'has-text-grey': !hasOpenChanges,
        }"
        :disabled="!hasOpenChanges"
        @click="hasOpenChanges && openActiveModal('Undo')"
        class="button is-ghost has-text-info-dark is-borderless"
      >
        <span class="icon is-small mr-1">
          <font-awesome-icon :icon="['fas', 'undo']" />
        </span>
        <span>
          {{ $t('Form.Control.Undo') }}
        </span>
      </a>
      <a
        @click="hasOpenChanges && saveChanges()"
        class="button is-success"
        :disabled="!hasOpenChanges || notAllFieldsAreCorrect"
        >{{ $t('Form.Control.Save') }}
      </a>
      <LinkIcon
        :text="
          $t('Components.Article.ArticleDetail.EditArticle.Text_DeleteArticle')
        "
        type="OnClick"
        :icon="['fas', 'trash-alt']"
        class="button is-ghost has-text-danger"
        @click.native="activeModal = 'DeleteArticle'"
      />
    </div>

    <hr />

    <div class="title is-size-3-mobile is-size-2-touch is-size-1-desktop">
      {{ articleFormData.Title || '&nbsp;' }}
    </div>
    <div class="columns is-multiline">
      <div class="column is-full is-two-thirds-desktop">
        <div ref="baseForm">
          <div class="block">
            <ImageCropper
              ref="imageCropper"
              :aspectRatio="3 / 2"
              :guides="false"
              :viewMode="3"
              :originalImage="originalImageUrl"
              @callbackImageChosen="
                (val) => {
                  newImageChosen = val
                }
              "
              @errorCallbackInvalid="
                (val) => {
                  hasImageError = val
                }
              "
              aspectRatioCssClass="is-3by2"
            >
              <div class="mb-5">
                <div class="has-text-weight-bold">
                  {{
                    $t(
                      'Components.Article.ArticleDetail.EditArticle.Label_Image'
                    )
                  }}
                </div>
                {{
                  $t('Components.Article.ArticleDetail.EditArticle.Text_Image')
                }}
              </div>
            </ImageCropper>
          </div>
          <div class="block">
            <BaseInputField
              :label="
                $t('Components.Article.ArticleDetail.EditArticle.Label_Title')
              "
              v-model="articleFormData.Title"
              :maxLength="100"
              :labelForInput="false"
              inputCssClass="is-medium"
              :error="titleErrors"
              @input="$v.articleFormData.Title.$touch()"
              @blur="$v.articleFormData.Title.$touch()"
            />
          </div>
          <div class="block content">
            <div class="has-text-weight-bold">
              {{
                $t(
                  'Components.Article.ArticleDetail.EditArticle.Label_ShortDescription'
                )
              }}
            </div>
            <p>
              {{
                $t(
                  'Components.Article.ArticleDetail.EditArticle.Text_ShortDescription'
                )
              }}
            </p>
            <ui-textarea
              v-model="articleFormData.Short"
              :maxLength="500"
              :labelForInput="false"
              inputCssClass="has-fixed-size"
              :error="shortTextErrors"
              @input="$v.articleFormData.Short.$touch()"
              @blur="$v.articleFormData.Short.$touch()"
            />
          </div>
          <div class="block content">
            <div class="has-text-weight-bold">
              {{
                $t('Components.Article.ArticleDetail.EditArticle.Label_Article')
              }}
            </div>
            <p>
              {{
                $t('Components.Article.ArticleDetail.EditArticle.Text_Article')
              }}
            </p>
            <ui-text-editor
              v-model="articleFormData.Text"
              :maxLength="12000"
              :labelForInput="false"
              inputCssClass="has-fixed-size textarea-articleBody"
              :error="textErrors"
              @input="$v.articleFormData.Text.$touch()"
              @blur="$v.articleFormData.Text.$touch()"
            />
          </div>
          <div class="block content">
            <div class="has-text-weight-bold">
              {{
                $t('Components.Article.ArticleDetail.EditArticle.Label_Tags')
              }}
            </div>
            <p>
              {{ $t('Components.Article.ArticleDetail.EditArticle.Text_Tags') }}
            </p>
            <TagInput v-model="articleFormData.Tags" />
          </div>
        </div>
      </div>

      <div class="column is-full is-one-third-desktop">
        <div
          class="box-otherSettings box is-shadowless has-background-primary-light mb-6"
        >
          <div class="title is-5">
            {{
              $t(
                'Components.Article.ArticleDetail.EditArticle.Title_PublicationSettings'
              )
            }}
          </div>
          <div class="row mb-4">
            <div class="has-text-weight-bold mb-1">
              {{
                $t(
                  'Components.Article.ArticleDetail.EditArticle.Label_PublicationState'
                )
              }}:
            </div>
            <ui-select
              v-model="articleFormData.Status"
              :options="publishStateOptions"
              objectValueField="value"
              objectTextField="text"
              :labelForInput="false"
              inputCssClass=""
            />
          </div>

          <div v-if="articleFormData.Type === 'Group'" class="row mb-4">
            <div class="has-text-weight-bold mb-1">
              {{
                $t(
                  'Components.Article.ArticleDetail.EditArticle.Label_OpenArticle'
                )
              }}:
            </div>
            <Toggle v-model="articleFormData.IsPublic" inputCssClass="" />
          </div>

          <div class="row mb-4">
            <div class="has-text-weight-bold mb-1">
              {{
                $t(
                  'Components.Article.ArticleDetail.EditArticle.Label_PublicationDate'
                )
              }}
            </div>
            <DateTimeInput
              :dates="articleFormData.Date"
              :showTime="false"
              inputCssClass=""
              :minDate="null"
              @action="(dates) => (articleFormData.Date = dates.startDate)"
              class="dateInput-publication"
            />
          </div>

          <div class="row mb-4">
            <div class="has-text-weight-bold mb-1">
              {{
                $t(
                  'Components.Article.ArticleDetail.EditArticle.Label_SetExpireDate'
                )
              }}
            </div>
            <div class="is-flex">
              <Toggle v-model="isSetExpireDate" inputCssClass="" />
              <DateTimeInput
                v-if="isSetExpireDate"
                :dates="articleFormData.ExpiresOn"
                :showTime="false"
                inputCssClass="is-fullwidth"
                :error="dateErrors"
                @input="$v.articleFormData.ExpiresOn.$touch()"
                @blur="$v.articleFormData.ExpiresOn.$touch()"
                @action="
                  (dates) => (articleFormData.ExpiresOn = dates.startDate)
                "
                class="ml-3 has-flex-grow-1"
              />
            </div>
          </div>

          <div class="row mb-4">
            <div class="has-text-weight-bold mb-1">
              {{
                $t(
                  'Components.Article.ArticleDetail.EditArticle.Label_AllowComments'
                )
              }}
            </div>
            <Toggle v-model="articleFormData.EnableComments" inputCssClass="" />
          </div>
        </div>

        <div class="block-externalSources block">
          <div class="title is-5 mb-4">
            {{
              $t(
                'Components.Article.ArticleDetail.EditArticle.Title_ExternalSouces'
              )
            }}
          </div>
          <div class="mb-4 has-text-right content">
            <LinkIcon
              :text="
                $t(
                  'Components.Article.ArticleDetail.EditArticle.Text_AddSource'
                )
              "
              type="OnClick"
              :icon="['fas', 'plus']"
              @click.native="openModalAddEditArticleSource()"
            />
          </div>

          <template v-if="Object.keys(articleSources).length">
            <template v-for="(sourceList, key, index) in articleSources">
              <div
                :key="'s' + index"
                class="block-source"
                :class="{ 'mt-6': index !== 0 }"
              >
                <div
                  v-for="(sourceItem, sIndex) in sourceList"
                  :key="'s' + index + sourceItem.Id"
                  :class="{ 'mt-2 pt-2 has-border-top': sIndex !== 0 }"
                  class="row-source"
                >
                  <LinkIcon
                    v-if="key === 'Link'"
                    :text="sourceItem.Text"
                    type="Link"
                    :newWindow="true"
                    :to="sourceItem.Link"
                    iconCssClass="is-small"
                    class="has-icon-background"
                  />
                  <div v-if="key === 'Video'">
                    <component
                      v-bind:is="getComponentType(sourceItem)"
                      :src="sourceItem.Link"
                      class="mb-2"
                    ></component>
                    <div class="has-text-weight-bold">
                      {{ sourceItem.Text }}
                    </div>
                  </div>
                  <div class="has-text-right content">
                    <LinkIcon
                      @click.native="openModalAddEditArticleSource(sourceItem)"
                      :text="$t('Form.Control.Edit')"
                      type="OnClick"
                      iconCssClass="is-small"
                      :icon="['fas', 'edit']"
                      class="mr-2"
                    />
                    <LinkIcon
                      @click.native="openModalDeleteArticleSource(sourceItem)"
                      :text="$t('Form.Control.Delete')"
                      type="OnClick"
                      iconCssClass="is-small"
                      :icon="['fas', 'trash-alt']"
                      class="has-text-danger"
                    />
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <hr />

    <div class="buttons is-right">
      <a
        v-if="!isSaving"
        @click="backToArticle()"
        class="button is-ghost has-text-primary-dark"
      >
        <span class="icon is-small mr-1">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </span>
        <span>
          {{ $t('Form.Control.Back') }}
        </span>
      </a>
      <a
        v-if="!isSaving"
        :class="{
          'has-text-primary-dark': hasOpenChanges,
          'has-text-grey': !hasOpenChanges,
        }"
        :disabled="!hasOpenChanges"
        @click="hasOpenChanges && openActiveModal('Undo')"
        class="button is-ghost has-text-info-dark"
      >
        <span class="icon is-small mr-1">
          <font-awesome-icon :icon="['fas', 'undo']" />
        </span>
        <span>
          {{ $t('Form.Control.Undo') }}
        </span>
      </a>
      <a
        @click="hasOpenChanges && saveChanges()"
        class="button is-success"
        :disabled="!hasOpenChanges || notAllFieldsAreCorrect"
        >{{ $t('Form.Control.Save') }}
      </a>
      <LinkIcon
        :text="
          $t('Components.Article.ArticleDetail.EditArticle.Text_DeleteArticle')
        "
        type="OnClick"
        :icon="['fas', 'trash-alt']"
        class="button is-ghost has-text-danger"
        @click.native="activeModal = 'DeleteArticle'"
      />
    </div>
  </div>
</template>

<script>
import articleProvider from '../../providers/article'
import uploadProvider from '../../providers/upload'
import hash from 'object-hash'
import { cleanSource, sortAscendingObject } from '@/utils/objectHelper'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import ImageCropper from '@/components/UI/Components/ImageCropper.vue'
import Loader from '@/components/UI/Loader.vue'
import LinkIcon from '@/components/UI/LinkIcon.vue'
import Modal from '@/components/UI/Components/Modal.vue'
import TagInput from '@/components/UI/Form/TagInput.vue'
import DateTimeInput from '@/components/UI/Form/DateTimeInput.vue'
import BaseInputField from '@/components/UI/Form/BaseInputField.vue'
import Toggle from '@/components/UI/Form/Toggle.vue'
import YoutubeEmbed from '@/components/UI/Components/YoutubeEmbed.vue'
import VimeoEmbed from '@/components/UI/Components/VimeoEmbed.vue'
import EventBus from '../../eventbus/event-bus'
import { format, isPast, parseISO, isBefore } from 'date-fns'
import isAfter from 'date-fns/isAfter'

export default {
  name: 'EditArticle',
  components: {
    Loader,
    LinkIcon,
    Modal,
    // BaseForm,
    ImageCropper,
    BaseInputField,
    // SelectField,
    TagInput,
    DateTimeInput,
    Toggle,
    ModalAddEditArticleSource: () =>
      import('@/components/Article/ModalAddEditArticleSource.vue'),
    ModalDeleteArticleSource: () =>
      import('@/components/Article/ModalDeleteArticleSource.vue'),
    ModalDeleteArticle: () =>
      import('@/components/Article/ModalDeleteArticle.vue'),
    YoutubeEmbed,
    VimeoEmbed,
  },

  data() {
    return {
      isLoading: true,
      isSaving: false,
      articleKey: this.$route.params.key,
      newImageChosen: false,
      originalArticle: null,
      articleFormData: null,
      isSetExpireDate: false,
      activeModal: '',
      selectedSource: null,
      hasImageError: false,
    }
  },

  validations() {
    let output = {
      Title: {
        required,
        minLength: minLength(10),
      },
      Short: {
        required,
        minLength: minLength(32),
        maxLength: maxLength(500),
      },
      Text: {
        required,
        minLength: minLength(100),
        maxLength: maxLength(12000),
      },
      ExpiresOn: {
        isAfter: (val) => {
          let publicationDate = parseISO(this.articleFormData.Date)
          let expiresOn = parseISO(val)
          return !this.isSetExpireDate || isAfter(expiresOn, publicationDate)
        },
      },
    }

    return {
      articleFormData: output,
    }
  },

  computed: {
    notAllFieldsAreCorrect() {
      return this.$v === null ? false : this.$v.$invalid
    },
    originalImageUrl() {
      if (!this.articleFormData?.ImageUrl) {
        return ''
      }
      return this.$options.filters.getArticleImage(
        this.articleFormData.ImageUrl,
        this.articleFormData.Id
      )
    },

    titleErrors() {
      const errors = []
      if (!this.$v.articleFormData.Title.$dirty) return errors
      !this.$v.articleFormData.Title.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.articleFormData.Title.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 10 }))
      return errors
    },

    shortTextErrors() {
      const errors = []
      if (!this.$v.articleFormData.Short.$dirty) return errors
      !this.$v.articleFormData.Short.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.articleFormData.Short.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 32 }))
      !this.$v.articleFormData.Short.maxLength &&
        errors.push(this.$t('Form.InputErrors.maxLength', { maxLength: 500 }))
      return errors
    },

    textErrors() {
      const errors = []
      if (!this.$v.articleFormData.Text.$dirty) return errors
      !this.$v.articleFormData.Text.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.articleFormData.Text.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 100 }))
      !this.$v.articleFormData.Text.maxLength &&
        errors.push(this.$t('Form.InputErrors.maxLength', { maxLength: 12000 }))
      return errors
    },

    dateErrors() {
      const errors = []
      !this.$v.articleFormData.ExpiresOn.isAfter &&
        errors.push('Expiration date is before publication date')
      return errors
    },

    hasOpenChanges() {
      return (
        hash(cleanSource(this.articleFormData)) !==
          hash(cleanSource(this.originalArticle)) || this.newImageChosen
      )
    },

    publishStateOptions() {
      return [
        {
          value: 'Draft',
          text: this.$t(
            'Components.Article.ArticleDetail.EditArticle.Text_ArticleStateDraft'
          ),
        },
        {
          value: 'Published',
          text: this.$t(
            'Components.Article.ArticleDetail.EditArticle.Text_ArticleStatePublish'
          ),
        },
        {
          value: 'Retracted',
          text: this.$t(
            'Components.Article.ArticleDetail.EditArticle.Text_ArticleStateRetracted'
          ),
        },
      ]
    },

    articleSources() {
      if (!this.articleFormData) return {}

      let output = {}

      this.articleFormData.Links.forEach((linkItem) => {
        let TYPE =
          linkItem.LinkType === 'ExternalUrl' ||
          linkItem.LinkType === 'InternalUrl'
            ? 'Link'
            : 'Video'
        if (!output.hasOwnProperty(TYPE)) {
          output[TYPE] = []
        }
        output[TYPE].push(linkItem)
      })

      output = sortAscendingObject(output)

      return output
    },
  },

  watch: {
    isSetExpireDate: {
      handler: function (boolValue) {
        if (boolValue) {
          const DATE_NOW = new Date()
          const Is_PAST_DATE = isPast(parseISO(this.articleFormData.ExpiresOn))

          this.articleFormData.ExpiresOn = Is_PAST_DATE
            ? format(
                new Date(DATE_NOW.setDate(DATE_NOW.getDate() + 30)),
                'yyyy-MM-dd'
              )
            : this.originalArticle.ExpiresOn
        } else {
          this.articleFormData.ExpiresOn = '1900-01-01'
        }
      },
    },
  },

  created() {
    this.getArticleData()
  },

  mounted() {
    let t = setTimeout(() => {
      this.$v.$touch()
      this.$refs.imageCropper.checkValidation()
      clearTimeout(t)
    }, 300)
  },

  methods: {
    getArticleData() {
      articleProvider.methods
        .getArticleByKey(this.articleKey)
        .then((response) => {
          if (response.status === 200) {
            this.articleFormData = cleanSource(response.data)
            this.originalArticle = cleanSource(response.data)
            this.isSetExpireDate =
              response.data.ExpiresOn.indexOf('1900') === -1
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false
        })
    },

    backToArticle() {
      let routeName = this.$route.name.split('-')[0]
      this.$router.push({ name: routeName + '-list' })
    },

    backToPreviousPage() {
      let routeName = this.$route.name.split('-')[0]
      this.$router.push({ name: routeName + '-list' })
    },

    getComponentType(videoSource) {
      let component = ''

      switch (videoSource.LinkType) {
        case 'YoutubeVideo':
          component = YoutubeEmbed
          break
        case 'VimeoVideo':
          component = VimeoEmbed
          break
      }
      return component
    },

    undoChanges() {
      if (!this.hasOpenChanges && !this.newImageChosen) {
        return
      }
      this.$refs.imageCropper.restoreImage()
      this.articleFormData = cleanSource(this.originalArticle)
      this.isSetExpireDate =
        this.articleFormData.ExpiresOn.indexOf('1900') === -1
      EventBus.$emit('showToast', {
        type: 'info',
        message: 'Changes have been reverted',
      })
      this.hideModal()
    },

    updatedSourceList(article) {
      this.articleFormData.Links = article.Links
      this.originalArticle.Links = article.Links
    },

    removeItemFromSourceList(linkId) {
      let links = cleanSource(this.articleFormData.Links)
      let linkIndex = links.findIndex((l) => l.Id === linkId)
      linkIndex !== -1 && this.$delete(links, linkIndex)
      this.articleFormData.Links = links
      this.originalArticle.Links = links
    },

    openModalAddEditArticleSource(source = null) {
      this.selectedSource = source
      this.activeModal = 'AddEditArticleSource'
    },

    openModalDeleteArticleSource(source = null) {
      this.selectedSource = source
      this.activeModal = 'DeleteArticleSource'
    },

    openActiveModal(modalType) {
      this.activeModal = modalType
    },

    hideModal() {
      this.activeModal = ''
      this.selectedSource = null
    },

    async saveChanges() {
      // Validate form
      await this.$v.$touch()

      // Stop processing when FORM data is invalid
      if (this.isSaving || this.$v.$invalid || this.hasImageError) {
        return
      }

      this.isSaving = true

      this.articleFormData.Title = this.$options.filters.stripHtml(
        this.articleFormData.Title
      )
      this.articleFormData.Short = this.$options.filters.stripHtml(
        this.articleFormData.Short
      )
      this.articleFormData.Text = this.articleFormData.Text

      try {
        if (this.newImageChosen) {
          const NEW_IMAGE = this.$refs.imageCropper.getCroppedCanvas()
          let responseImageUpload =
            await uploadProvider.methods.postArticleImage(
              this.articleFormData.Id,
              NEW_IMAGE
            )
          if (responseImageUpload.status === 200) {
            this.articleFormData.ImageUrl = responseImageUpload.data
          }
        }

        let response = await articleProvider.methods.updateArticle(
          this.articleFormData
        )
        this.articleFormData = cleanSource(response.data)
        this.originalArticle = cleanSource(response.data)
        this.newImageChosen = false
        this.$refs.imageCropper.reset()

        EventBus.$emit('showToast', {
          type: 'info',
          message: this.$t('General.SavingSuccess'),
        })
      } catch {
      } finally {
        this.isSaving = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/template.scss"

.dateInput-publication
  max-width: 185px

::v-deep .wrapper-imageCropper
  display: flex

  @media screen and (min-width: $desktop)
    flex-direction: row
    .img-container-wrapper
      max-width: 70%
    .cropper-controls
      padding-right: $gap
      .upload-control
        align-items: flex-end

  .cropper-controls
    flex-grow: 1
    .upload-controls
      display: flex
      flex-direction: column
  .img-container-wrapper
    width: 100%

::v-deep .textarea-articleBody
  height: 400px

.block-source
  .row-source
    &.has-border-top
      border-top: 1px dashed $grey-light
</style>
