<template>
  <div>
    <EditArticle />
  </div>
</template>

<script>
import EditArticle from '../../components/Article/EditArticle.vue'
export default {
  name: 'PostEdit',

  components: {
    EditArticle
  }
}
</script>

<style lang="scss" scoped>
</style>