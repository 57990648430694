<template>
  <div
    class="toggle input-toggle input is-inline-flex pl-0 pr-0"
    :class="inputCssClass"
  >
    <a
      class="button"
      :class="[toggleBtnCssClass, isSelected(ro) ? 'is-info' : 'is-ghost has-text-info-dark has-text-weight-semibold']"
      v-for="(ro, index) in radioOptions"
      :key="'ro_'+index"
      @click="changeChoise(ro)"
    >
      <input
        type="radio"
        :value="getOptionValue(ro)"
      />
      <span v-text="getOptionText(ro)"></span>
    </a>
  </div>
</template>

<script>
import BaseInputFieldController from '@/components/UI/Form/BaseInputFieldController.vue'

export default {
  name: 'Toggle',
  extends: BaseInputFieldController,
  props: {
    radioOptions: {
      type: Array,
      default: function() {
        return [
          {
            boolSettings: false,
            text: this.$root.$t('Form.Control.No'),
          },
          {
            boolSettings: true,
            text: this.$root.$t('Form.Control.Yes'),
          },
        ]
      }
    },

    objectTextField: {
      type: String,
      default: 'text'
    },

    objectValueField: {
      type: String, Number, Object,
      default: 'boolSettings'
    },

    setSelectedValue: {
      type: Function,
      required: false,

    }
  },

  computed: {
    toggleBtnCssClass() {
      return this.inputCssClass
        .replace('is-normal', '')
        .replace('is-medium', '')
        .replace('is-large', '')
        .replace('is-extralarge', '')
    }
  },

  methods: {
    getOptionText(ro) {
      return this.objectTextField ? ro[this.objectTextField] : ro
    },

    getOptionValue(ro) {
      return this.objectValueField ? ro[this.objectValueField] : ro
    },

    isSelected(ro) {
      return String((this.objectValueField ? ro[this.objectValueField] : ro)) === String(this.value)
    },

    changeChoise(ro) {
      this.setSelectedValue && this.setSelectedValue(this.objectValueField ? ro[this.objectValueField] : ro)
      this.$emit('input', this.objectValueField ? ro[this.objectValueField] : ro)
    }
  }
}
</script>

<style lang="sass" scoped>
.input-toggle
  width: auto
  &.is-fullwidth
    width: 100%
  a
    flex: 1 1 50%
    input
      opacity: 0
      width: 0
      height: 0
</style>