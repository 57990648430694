<template>
  <transition-group name="fade" mode="out-in" @after-leave="onAfterLeave">
    <div key="dateTimePickerBackground" class="dpPopup-background"></div>
    <div key="dateTimePicker" :ref="refName" class="dpPopup">
      <div class="card">
        <v-date-picker
          mode="single"
          v-model="dateTime.dates"
          :is-inline="true"
          :is-required="true"
          :isExpanded="true"
          :min-date="minDate"
          :max-date="maxDate"
          :columns="$screens(screens)"
          :model-config="modelConfig"
        />

        <div v-if="!nrOfLocations" class="notification m-4 p-3 is-warning">
          <span
            >{{ $t('components.Wizard.SearchForm.Warning_VenueClosed') }}
          </span>
        </div>

        <div v-if="showTimeSelectors" class="dpTimeSelectors is-flex">
          <strong class="mr-2">
            <i18n path="components.Wizard.SearchForm.Label_Time"></i18n>:
          </strong>

          <div v-if="showAllDaySetting">
            <BaseCheckbox
              v-model="allDayChecked"
              :label="$t('General.Text_IsAllDay')"
            />
          </div>
          <div
            class="dpTimeSelectors"
            :class="{ 'pt-0 pb-2': showAllDaySetting }"
          >
            <div :class="[`select is-rounded `, { 'is-loading': isLoading }]">
              <select
                class="is-shadowless"
                :disabled="isLoading || allDayChecked"
                v-model="dateTime.startMinutes"
              >
                <option
                  v-for="(option, index) in timeslots"
                  :key="index"
                  :value="option"
                  :selected="option === dateTime.startMinutes"
                >
                  {{ option | minutesToTime }}
                </option>
              </select>
            </div>
            <span class="icon">
              <font-awesome-icon :icon="['far', 'minus']" />
            </span>
            <div :class="[`select is-rounded `, { 'is-loading': isLoading }]">
              <select
                class="is-shadowless"
                :disabled="isLoading || allDayChecked"
                v-model="dateTime.endMinutes"
              >
                <option
                  v-for="(option, index) in timeslots"
                  :key="index"
                  :value="option"
                  :selected="option === dateTime.endMinutes"
                >
                  {{ option | minutesToTime }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <footer v-if="confirmChoice" class="card-footer">
          <a
            @click="hideDatePicker()"
            class="card-footer-item"
            v-text="$t('Form.Control.Cancel')"
          ></a>
          <a
            @click="applyNewDates()"
            :disabled="isLoading || !nrOfLocations"
            :class="[
              { 'has-text-grey is-disabled': isLoading || !nrOfLocations },
              `card-footer-item has-text-weight-bold`,
            ]"
            v-text="$t('Form.Control.Apply')"
          ></a>
        </footer>
      </div>
    </div>
  </transition-group>
</template>

<script>
import Vue from 'vue'
import { format } from 'date-fns/esm'
import VCalendar from 'v-calendar'
Vue.use(VCalendar)
// import { mapActions, mapState } from 'vuex'

export default {
  name: 'DateTimeInputPopup',
  components: {
    BaseCheckbox: () => import('@/components/UI/Form/BaseCheckbox'),
  },

  props: {
    target: {
      default: null,
      required: true,
    },

    minDate: {
      type: String,
      default: function () {
        return format(new Date(), 'yyyy-MM-dd')
      },
    },
    maxDate: {
      type: String,
      default: function () {
        return format(new Date(), 'yyyy-MM-dd')
      },
    },

    dates: {
      type: [Date, String, Object],
      default: function () {
        return format(new Date(), 'yyyy-MM-dd')
      },
    },

    showTime: {
      type: Boolean,
      default: true,
    },

    startMinutes: {
      type: Number,
      default: 540,
    },
    endMinutes: {
      type: Number,
      default: 960,
    },

    confirmChoice: {
      type: Boolean,
      default: true,
    },

    mode: {
      type: String,
      default: 'single',
    },

    use24HourTimeSlot: {
      type: Boolean,
      default: false,
    },
    screens: {
      type: Object,
      default: function () {
        return { default: 1 }
      },
    },
    showAllDaySetting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      refName: `popup${this.target}`,
      $popupElm: null,
      refDatePicker: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      ready: false,
      trigger: document.getElementById(this.target),
      readyCounter: 0,
      isLoading: false,
      dateTime: {
        dates: '',
        startMinutes: this.startMinutes,
        endMinutes: this.endMinutes,
      },
      openingHoursData: null,
      nrOfLocations: 1,
      allDayChecked: false,
      timeslots: this.build24HoursTimeslots(),
    }
  },

  computed: {
    showTimeSelectors() {
      return this.mode !== 'range' && this.nrOfLocations && this.showTime
    },

    datePickerReady() {
      if (
        (this.readyCounter >= 1 && this.mode === 'single') ||
        (this.readyCounter >= 2 && this.mode === 'range')
      ) {
        return true
      } else {
        return false
      }
    },
  },

  watch: {
    allDayChecked(val) {
      if (val) {
        this.dateTime.startMinutes = 0
        this.dateTime.endMinutes = 1440
      }
    },
  },

  created() {},

  mounted() {
    // Append modal to body
    this.$popupElm = this.$el
    document.body.appendChild(this.$popupElm)

    this.dateTime.dates =
      typeof this.dates === 'string' ? this.dates : { ...this.dates }
    this.ready = true

    document.addEventListener('scroll', this.scrollHandler, true)
    this.refDatePicker = this.$refs[this.refName]
    let t = setTimeout(() => {
      this.scrollHandler()
      clearTimeout(t)
    }, 20)
  },

  beforeDestroy() {
    this.resetBeforeDestroy()
  },

  methods: {
    onAfterLeave() {
      let modals = document.querySelectorAll('.modal')
      //console.log(modals)
    },

    build24HoursTimeslots() {
      let timeslots = []
      for (var i = 0; i <= 1440; i = i + 30) {
        timeslots.push(i)
      }
      return timeslots
    },

    resetBeforeDestroy() {
      // Remove listeners
      document.removeEventListener('scroll', this.scrollHandler)

      // Hide date picker
      this.ready = false

      // Remove node from body
      this.$el.parentNode && this.$el.parentNode.removeChild(this.$el)
    },

    /**
     * Date time popup actions
     */
    hideDatePicker() {
      this.resetBeforeDestroy()
      this.$emit('hide')
    },

    /**
     * Update date picker location on scroll
     */
    scrollHandler() {
      // Check if datePickerRects is not undefined. After second time opening the date picker this issue occuers
      const viewPortWidth = window.innerWidth
      const viewPortHeight = window.innerHeight
      const triggerRects = this.trigger.getBoundingClientRect()
      const datePickerRects = this.refDatePicker.getBoundingClientRect()
      const vSpaceGap = 5
      const hSpaceGap = 15

      let canBeAddedAbove =
        triggerRects.top - vSpaceGap - datePickerRects.height > 15
      let canBeAddedBelow =
        viewPortHeight -
          (triggerRects.top +
            triggerRects.height +
            vSpaceGap +
            datePickerRects.height) >
        15

      // Position datepicker below the input
      this.refDatePicker.style.top = `${
        triggerRects.top + triggerRects.height + vSpaceGap
      }px`

      if (!canBeAddedAbove && canBeAddedBelow) {
        // Position datepicker below the input
        this.refDatePicker.style.top = `${
          triggerRects.top + triggerRects.height + vSpaceGap
        }px`
      }

      if (canBeAddedAbove && !canBeAddedBelow) {
        // Place the datepicker above input
        this.refDatePicker.style.top = `${
          triggerRects.top - vSpaceGap - datePickerRects.height
        }px`
      }

      if (!canBeAddedAbove && !canBeAddedBelow) {
        // Center the datepicker
        this.refDatePicker.style.top =
          (viewPortHeight - datePickerRects.height) / 2 + 'px'
      }

      // Position date picker vertical in the center of the trigger element
      this.refDatePicker.style.left = `${
        triggerRects.left - datePickerRects.width / 2 + triggerRects.width / 2
      }px`

      if (
        triggerRects.left -
          datePickerRects.width / 2 +
          triggerRects.width / 2 -
          hSpaceGap <=
        0
      ) {
        // Date picker falls to the right of the screen. Set date picker due to the value of the hSpaceGap from the left of the screen
        this.refDatePicker.style.left = `${hSpaceGap}px`
      } else if (
        triggerRects.left -
          datePickerRects.width / 2 +
          triggerRects.width / 2 +
          datePickerRects.width +
          hSpaceGap >=
        viewPortWidth
      ) {
        // Date picker falls to the right of the screen. Set date picker due to the value of the hSpaceGap from the right of the screen
        this.refDatePicker.style.left = null
        this.refDatePicker.style.right = `${hSpaceGap}px`
      }
    },

    /**
     * Proces dates and time methods
     */
    applyNewDates() {
      if (this.isLoading || this.timeslots.length < 2) {
        return
      }
      this.$emit('update', this.dateTime)
      this.hideDatePicker()
    },
  },
}
</script>
