<template>
  <figure
    class="image is-16by9 wrapper-embed"
    ref="youtubePlaceHolder"
    v-html="buildIframe()"
  ></figure>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },

  methods: {
    buildIframe() {
      let iframe = `
        <iframe 
          width="100%"
          height="100%"
          src="${this.getPlayerUrl()}?portrait=0" 
          class="has-ratio"
          frameborder="0" 
          allow="autoplay; fullscreen" allowfullscreen
          allowTransparency="true"
        >
        </iframe>
      `
      return iframe
    },

    getPlayerUrl() {
      let src = this.src
      let videoId = ''
      /**
       * Check if URL contains /watch
       * Or youtube short URL
       */
      if (src.indexOf('/video') !== -1) {
        // Check if URL is the embed URL
        videoId = src.split('video/')[1].src.split('?')[0]
      } else {
        // Is a direct video URL
        videoId = src.split('vimeo.com/')[1]
      }
      return 'https://player.vimeo.com/video/' + videoId
    }
  }
}
</script>

<style lang="scss" scoped>
iframe {
  background: transparent;
}
</style>