<template>
  <div>
    <transition-group
      name="fade"
      mode="out-in"
      tag="div"
      class="tagsarea textarea is-rounded field is-grouped is-grouped-multiline"
      :class="{ 'is-static': isStatic, 'is-borderless': inputBorderless, 'is-danger': hasError }"
    >
      <div
        v-for="(tag, index) in mTags"
        :key="'t_' + index"
        class="control"
      >
        <div class="tags">
          <span class="tag is-white">
            <span>{{ tag }}</span>
            <a
              v-if="!isStatic"
              @click="removeTag(index)"
              class="icon is-small has-text-danger has-text-white is-rounded"
            >
              <font-awesome-icon :icon="['fas', 'trash-alt']" />
            </a>
          </span>
        </div>
      </div>
      <span
        v-if="!isStatic && editMode"
        key="tagInput"
        class="wrapper-input"
      >
        <input
          ref="tagInput"
          type="text"
          v-model="tagInput"
          class="input input-tag"
          :class="{'is-danger': hasError}"
          :placeholder="$t('Form.Control.AddTag')"
          @keyup.enter="addTag"
          @keyup.tab="addTag"
          @blur="addTag()"
        />
        <a
          @click="addTag"
          key="addTagButton"
          class="button-add button is-small is-rounded is-primary"
          :class="{ 'is-success': tagInput, 'is-success is-light': !tagInput }"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
        </a>
      </span>
    </transition-group>
    <transition-expand>
      <div v-if="error">
        <span
          v-if="!Array.isArray(error)"
          class="help is-danger"
          v-html="error"
        ></span>
        <div
          v-else
          class="help is-danger"
        >
          <div
            v-for="(message, index) in error"
            :key="`e${index}`"
            v-html="message"
          ></div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import { cleanSource } from '@/utils/objectHelper'
import TransitionExpand from '@/components/UI/TransitionExpand.vue'
export default {
  name: 'TagInput',

  components: {
    TransitionExpand
  },

  props: {
    editMode: {
      type: Boolean,
      default: true,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    inputBorderless: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: [Array, String],
      default: function() {
        return []
      },
    },
    value: {
      type: [String, Array],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
      note: 'Whether the input field is required. Overrides validationRules.'
    },
    validationRules: {
      type: Object,
      default: () => {
        return {}
      },
      note: 'An object with Vuelidate validation rules'
    },
    errorMessages: {
      type: Object,
      default: () => {
        return {}
      },
      note:
        'An object with error messages. The keys should be the same as the Vuelidate validation rules.'
    },

    showAllOpenErrors: {
      type: Boolean,
      default: false,
      note: 'When true all open errors will shown'
    },
  },

  data() {
    return {
      isLoadingTagsFromQuestion: false,
      tagInput: '',
      mTags: [],
      tagInputPlaceHolder: 'Add tag',
      timer: null,
      error: ''
    }
  },

  computed: {
    hasError() {
      return this.error.length
    }
  },

  mounted() {
    let output = []
    if (typeof this.value === 'string') {
      let _tags = this.value.trim().toLowerCase()
      output = _tags.length !== 0 ? _tags.split(',') : []
    }
    else {
      output = cleanSource(this.value)
    }

    if (typeof this.tags === 'string') {
      let _tags = this.tags.trim().toLowerCase()
      _tags = _tags.length !== 0 ? _tags.split(',') : []
      output = [..._tags, ...output]
    }
    else {
      output = [...output, ...cleanSource(this.tags)]
    }

    // if (this.value.trim()) {
    //   this.mTags = [...this.value.trim().split(','), ...this.mTags]
    // }

    this.mTags = output
  },

  methods: {
    /**
     * Add tag to tags array
     */
    addTag() {
      if (this.tagInput && this.tagInput.length > 0) {
        let tag = this.tagInput.toLowerCase()
        let arr = this.tagInput.split(/[\*+-/_#]/)

        for (let i in arr) {
          if (arr[i].trim() && !this.tagAlreadyAdded(arr[i].trim())) {
            this.mTags.push(arr[i].trim())
          }
        }
        arr = []

        this.tagInput = ''
        this.$refs.tagInput.focus()

        this.$emit('input', typeof this.value === 'string' ? this.mTags.join(',') : this.mTags)
        this.$emit('tagAdded', tag)
        this.$emit('updatedTags', this.mTags)
      }

      this.validate()
    },

    /**
     * Remove tag from array
     */
    removeTag(index) {
      this.$emit('tagDeleted', this.tags[index])
      this.mTags.splice(index, 1)
      this.$emit('input', typeof this.value === 'string' ? this.mTags.join(',') : this.mTags)
      this.$emit('updatedTags', this.mTags)
      this.validate()
    },

    /**
     * Check if tag already exists in array
     */
    tagAlreadyAdded(tag) {
      let index = this.mTags.indexOf(tag)
      return index > -1
    },

    validate() {
      let rules = Object.keys(this.validationRules) || []
      if (this.required) {
        rules['required'] = this.required
      }

      let message = ''
      let messages = []


      // Go through the rules, and exit at the first invalid rule
      rules.some(rule => {
        if (this.validationRules[rule](this.mTags)) {
          // Check for a custom message, fall back to the default
          if (this.errorMessages[rule]) {
            message = this.errorMessages[rule]
            messages.push(this.errorMessages[rule])
          }
          return !this.showAllOpenErrors
        }
      })

      this.error = !this.showAllOpenErrors ? message : messages
      return this.error.length ? 'Failed' : 'Passed'
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template.scss";
.row-whyTags {
  display: flex;
  align-items: baseline;
  .message {
    margin-bottom: 0;
    padding: 2px 5px;
  }
}

.is-borderless,
.is-borderless:hover {
  border-color: transparent;
}

.tagsarea {
  min-height: 52px;
}

.field.is-grouped.is-grouped-multiline {
  align-items: baseline;
  // background-color: white
  min-height: 52px;
  height: auto;

  &:last-child {
    margin-bottom: 0.5 * $gap;
  }

  .control {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .tags {
    &:last-child {
      margin-bottom: 0;
    }
    .tag {
      display: inline-flex;
      margin-bottom: 0;
      align-items: center;
      border: 1px solid $body-color;

      span {
        padding: 0 4px;
        font-size: $size-6;
      }
    }
  }
  .wrapper-input {
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    .input-tag {
      min-width: 140px;
      font-size: $size-6;
      width: 140px;
      margin-right: 5px;
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
      border-bottom: 1px solid $grey;
      height: 1.75em;
      &:focus {
        box-shadow: none;
        border-bottom: 1px solid $green;
      }
    }
  }

  .button-add {
    width: 24px;
    height: 24px;
    padding: 0;
  }
}
</style>
