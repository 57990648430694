<template>
  <div>
    <BaseInputField
      :ref="inputId"
      :id="inputId"
      :label="label"
      :hasFloatingLabel="hasFloatingLabel"
      :inputCssClass="inputCssClass"
      :value="datePresentation"
      :placeholder="placeholder"
      :disabled="disabled"
      :isStatic="isStatic"
      :error="error"
      readonly="readonly"
      @click.native="showPopup()"
      @focus="removeFocusFromInput"
      :iconProps="iconProps"
      :labelForInput="false"
    />

    <DateTimeInputPopup
      Popup
      v-if="popupShown"
      :ref="refDatePickerPopup"
      :key="mode"
      :mode="mode"
      :dates="mDates"
      :minDate="minDate"
      :maxDate="maxDate"
      :showTime="showTime"
      :startMinutes="mStartMinutes"
      :endMinutes="mEndMinutes"
      :target="inputId"
      :use24HourTimeSlot="use24HourTimeSlot"
      :screens="screens"
      :showAllDaySetting="showAllDaySetting"
      @update="update"
      @hide="hideDatePicker()"
      v-click-outside-custom.bubble="{
        exclude: [inputId],
        handler: 'hideDatePicker',
      }"
    />
  </div>
</template>

<script>
import { format, intlFormat, parseISO } from 'date-fns/esm'
import BaseInputField from './BaseInputField.vue'
import DateTimeInputPopup from './DateTimeInputPopup.vue'
export default {
  name: 'DateTimeInput',

  components: {
    BaseInputField,
    DateTimeInputPopup
  },

  props: {
    dates: {
      type: [Date, String, Object],
      default: function() {
        return format(new Date(), 'yyyy-MM-dd')
      },
    },

    error: {
      type: [String, Array],
      default: '',
    },

    dateFormat: {
      type: Object,
      default: function() {
        return {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
      },
    },

    showTime: {
      type: Boolean,
      default: true
    },

    minDate: {
      type: String,
      default: function() {
        return format(new Date(), 'yyyy-MM-dd')
      },
    },

    maxDate: {
      type: String,
      default: function() {
        return ''
      },
    },

    mode: {
      type: String,
      default: 'single',
    },
    startMinutes: {
      type: Number,
      default: 540,
    },
    endMinutes: {
      type: Number,
      default: 960,
    },
    use24HourTimeSlot: {
      type: Boolean,
      default: false,
    },
    inputCssClass: {
      type: String,
      default: '',
      note: 'Bulma class name size. is-small, is-medium, is-large. By leaving this empty the size will be set to normal.',
    },
    hasFloatingLabel: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
      note: 'The field label and/or instruction text. Strongly recommended, but optional.',
    },
    iconProps: {
      type: Object,
      default: null,
      note: `{
        wrapperCssClass: 'is-small', // Bulma icon size wrapper
        colorCssClass: 'has-text-primary-dark',
        bundle: 'fas', // bundle: 'fas (solid), fal (light), far (regular), fad (duotone), fab (brand)'
        icon: '', // icon type
        size: '1x', // size of the icon
        flip: '', // String: '', 'horizontal', 'vertical' or 'both'
      }`,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Whether the input field is disabled',
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    screens: {
      type: Object,
      default: function() {
        return { default: 1 }
      },
    },
    showAllDaySetting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inputId: `dpInput${new Date().getTime()}`,
      refDatePickerPopup: `popupWrapper${new Date().getTime()}`,
      mDateFormat: this.dateFormat,
      popupShown: false,
      handleOutsideClick: null,
      mDates: this.dates,
      mStartMinutes: this.startMinutes,
      mEndMinutes: this.endMinutes,
    }
  },

  computed: {
    datePresentation() {
      let output = ''
      if (this.mode === 'single') {
        //instead of range
        output = intlFormat(
          //output gives date, not time
          parseISO(this.mDates),
          this.mDateFormat,
          {
            locale: this.$i18n.locale,
          }
        )
        if (this.showTime) {
          output += `    ${this.$options.filters.minutesToTime(
            this.mStartMinutes
          )} - ${this.$options.filters.minutesToTime(this.mEndMinutes)}` //output 0730 - 1700
        }
      } else {
        output = `${intlFormat(
          parseISO(this.mDates.start),
          this.mDateFormat,
          {
            locale: this.$i18n.locale,
          }
        )} - ${intlFormat(
          parseISO(this.mDates.end),
          this.mDateFormat,
          {
            locale: this.$i18n.locale,
          }
        )}`
      }
      return output
    },
  },

  watch: {
    dateFormat: {
      immediate: true,
      handler: function(val) {
        this.mDateFormat = { ...this.mDateFormat, ...val }
      },
    },

    startMinutes: {
      handler: function(val) {
        this.mStartMinutes = val
      },
    },

    endMinutes: {
      handler: function(val) {
        this.mEndMinutes = val
      },
    },

    dates: {
      handler: function(val) {
        this.mDates = val
      },
    },
  },

  mounted() {
    window.addEventListener('resize', this.reCalculateDateTimeInput)
    this.reCalculateDateTimeInput()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.reCalculateDateTimeInput)
  },

  methods: {
    /**
     * Determine the month indication based on the input width
     */
    reCalculateDateTimeInput() {
      this.mDateFormat.month =
        this.$el.offsetWidth < 326 || this.mode === 'range'
          ? 'short'
          : this.dateFormat.month
    },

    /**
     * Remove focus from input field
     * and show date time picker
     */
    removeFocusFromInput(elm) {
      elm.target.blur()
    },

    /**
     * Show or hide popup
     */
    showPopup() {
      if (this.disabled || this.isStatic) { return }
      this.popupShown = true
    },

    /**
     * Hide date time picker
     */
    hideDatePicker() {
      this.popupShown = false
    },

    doApplyBeforeClosing() {
      this.$refs[this.refDatePickerPopup].applyNewDates()
    },

    // Update dates
    update(val) {
      this.mDates = val.dates
      this.mStartMinutes = val.startMinutes
      this.mEndMinutes = val.endMinutes

      // this.setDateTime(this.openingHoursData)
      this.$emit('action', {
        startDate: val.dates.start ? val.dates.start : val.dates,
        endDate: val.dates.end ? val.dates.end : val.dates,
        startMinutes: val.startMinutes,
        endMinutes: val.endMinutes,
      })
    },
  },

  directives: {
    'click-outside-custom': {
      bind(el, binding, vnode) {
        // Here's the click/touchstart handler
        // (it is registered below)
        vnode.context.handleOutsideClick = (e) => {
          e.stopPropagation()

          // Get the handler method name and the exclude array
          // from the object used in v-closable
          const { handler, exclude } = binding.value

          // This variable indicates if the clicked element is excluded
          let clickedOnExcludedEl = false

          if (typeof exclude !== 'undefined') {
            exclude.forEach((refName) => {
              // We only run this code if we haven't detected
              // any excluded element yet
              if (!clickedOnExcludedEl) {
                // Get the element using the reference name
                let excludedEl = vnode.context.$refs[refName]
                // See if this excluded element
                // is the same element the user just clicked on
                if (typeof excludedEl !== 'undefined') {
                  clickedOnExcludedEl = (
                    typeof excludedEl === 'object' ? excludedEl.$el : excludedEl
                  ).contains(e.target)
                }
              }
            })

            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
              // If the clicked element is outside the dialog
              // and not the button, then call the outside-click handler
              // from the same component this directive is used in
              vnode.context[handler]()
            }
          }
        }
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', vnode.context.handleOutsideClick)
        // document.addEventListener('touchstart', vnode.context.handleOutsideClick)
      },

      unbind(el, binding, vnode) {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', vnode.context.handleOutsideClick)
        // document.removeEventListener('touchstart', vnode.context.handleOutsideClick)
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
