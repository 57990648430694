<template>
  <div class="wrapper-imageCropper">
    <div
      v-if="!isStatic"
      class="cropper-controls is-flex-grow-1"
    >
      <slot></slot>
      <div class="upload-controls mb-5 content">
        <div class="is-inline-flex is-align-items-center mb-5">
          <a
            v-if="showRestoreIcon"
            @click="restoreImage()"
            class="icon mr-4"
          >
            <font-awesome-icon :icon="['fas', 'undo']" />
          </a>
          <div class="file is-success">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                :accept="String(allowedFileTypes)"
                @change="onFileChanged"
                rel="fileInput"
              />
              <span class="file-cta">
                <span class="file-label">
                  <template v-if="uploadButtonView !== 'text'">
                    <span class="icon">
                      <font-awesome-icon :icon="['far', 'camera']" />
                    </span>
                    <span v-if="uploadButtonView !== 'icon'">{{$t('Form.Control.Choose')}}</span>
                  </template>
                  <template v-if="uploadButtonView === 'text'">
                    {{$t('Form.Control.ChooseImage')}}
                  </template>
                </span>
              </span>
            </label>
          </div>
        </div>

        <!-- Zoom control -->
        <div
          v-if="showSlider"
          class="slider-field is-flex field mb-5"
        >
          <strong class="icon is-size-5">-</strong>
          <input
            v-model.number="zoomSlider"
            class="slider is-medium is-circle is-fullwidth"
            step="0.1"
            v-bind:min="0.1"
            v-bind:max="0.8"
            type="range"
            @input="cropperZoomTo"
          />
          <strong class="icon is-size-5">+</strong>
        </div>
        <div
          v-if="errorMessages.length"
          class="errorMessages has-text-danger is-size-7"
        >
          <span
            v-if="!Array.isArray(errorMessages)"
            class="help is-danger"
            v-html="errorMessages"
          ></span>
          <div
            v-else
            class="help is-danger"
          >
            <div
              v-for="(message, index) in errorMessages"
              :key="`e${index}`"
              v-html="message"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="img-container-wrapper">
      <div
        v-if="isLoading"
        class="loader-wrapper is-flex is-align-items-center is-justify-content-center"
      >
        <Loader class="custom-loader" />
      </div>
      <div>
        <div
          class="image img-container"
          :class="[aspectRatioCssClass, imageContainerCssClass]"
          :style="cropperContainerStyle"
        >
          <img
            id="image"
            class="originalImage"
            :src="imageURL"
            :style="{aspectRatio: aspectRatio}"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Cropper from 'cropperjs';
// import 'cropperjs/dist/cropper.css';
import Loader from '../Loader.vue'
import EventBus from '../../../eventbus/event-bus';
export default {
  name: 'ImageCropper',

  components: {
    Loader,
  },

  props: {
    allowedFileTypes: {
      type: Array,
      default: function() {
        return [
          'image/png',
          'image/jpg',
          'image/jpeg',
        ]
      }
    },
    cropperContainerStyle: {
      type: [String, Object],
      default: null,
    },

    alwaysHideRestore: {
      type: Boolean,
      default: false
    },

    isStatic: {
      type: Boolean,
      default: false
    },

    uploadButtonView: {
      type: String,
      default: 'text',
      note: 'Options are: text | icon-text | icon'
    },

    aspectRatio: {
      type: Number,
      default: 4 / 3
    },

    aspectRatioCssClass: {
      type: String,
      default: 'is-4by3'
    },

    imageContainerCssClass: {
      type: String,
      default: ''
    },

    autoCropArea: {
      type: Number,
      default: 1
    },

    dragMode: {
      type: String,
      default: 'move'
    },

    guides: {
      type: Boolean,
      default: true
    },

    previewContainer: {
      type: String,
      default: ''
    },

    minContainerWidth: {
      type: Number,
      default: 100
    },

    minContainerHeight: {
      type: Number,
      default: 100
    },

    minWidth: {
      type: Number,
      default: 1024
    },

    minHeight: {
      type: Number,
      default: 1024
    },

    maxWidth: {
      type: Number,
      default: 1920
    },
    maxHeight: {
      type: Number,
      default: 1920
    },

    width: {
      type: Number,
      default: 0
    },

    height: {
      type: Number,
      default: 0
    },

    viewMode: {
      type: Number,
      default: 3
    },

    originalImage: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      imageURL: '',
      inValidImage: false,
      imgCropper: null,
      previewReady: false,
      isMinimalSizeAtLoad: false,
      isWrongFileType: false,
      imageCheckedTimer: null,
      newImageChosen: false,
      isLoading: false,
      imageErrorMessages: {
        required: this.$t('Form.InputErrors.ImageIsRequired'),
        inValidImage: this.$t('Form.InputErrors.ImageTooSmall'),
        isWrongFileType: this.$t('Form.InputErrors.WrongType'),
      },
      notValidated: true,
      zoomSlider: 0.1
    }
  },

  computed: {
    imageValidationRules() {
      let rules = {
        required: () => { return !this.required },
        inValidImage: () => { return !this.inValidImage },
        isWrongFileType: () => { return !this.isWrongFileType }
      }
      return rules
    },

    showRestoreIcon() {
      return !this.alwaysHideRestore && this.newImageChosen && this.originalImage !== ''
    },

    showSlider() {
      return this.previewReady && !this.isMinimalSizeAtLoad
    },

    isValid() {
      return !this.inValidImage && !this.isWrongFileType
    },

    noValidImageChosen() {
      return this.newImageChosen && (this.inValidImage || !this.previewReady || this.isWrongFileType)
    },

    errorMessages() {
      let output = []

      if (this.required && !this.notValidated && !this.originalImage && !this.newImageChosen) { output.push(this.imageErrorMessages.required) }
      this.inValidImage && output.push(this.imageErrorMessages.inValidImage)
      this.isWrongFileType && output.push(this.imageErrorMessages.isWrongFileType)
      return output
    }
  },

  watch: {
    noValidImageChosen: {
      handler: function(val) {
        this.$emit('errorCallbackInvalid', val)
      }
    },
  },

  created() {
    this.setStartImage()
  },

  beforeDestroy() {
    this.imageURL && URL.revokeObjectURL(this.imageURL)
  },

  methods: {
    checkValidation() {
      this.notValidated = false
    },

    setStartImage() {
      this.imageURL = '/img/noImage.png'
      if (!this.originalImage) { return }

      let isExistingImage = this.originalImage.indexOf('.jpg') !== -1
      this.imageURL = isExistingImage ? this.originalImage + '?' + performance.now() : this.originalImage
    },

    /**
     * Render image cropper
     */
    renderCropper(imageSource) {
      let _this = this
      const previews = this.previewContainer ? document.querySelectorAll(this.previewContainer) : []
      const image = document.getElementById('image')
      this.previewReady = false
      image.src = imageSource

      // First destroy cropperjs instance when exists before creating instance 
      this.imgCropper && this.imgCropper.destroy()

      // Delete all childs in preview elements
      _this.each(previews, function(elem) {
        while (elem.firstChild) {
          elem.removeChild(elem.firstChild)
        }
      })

      // Create cropper instance
      this.imgCropper = new this.$Cropper(image, {
        initialAspectRatio: this.aspectRatio,
        aspectRatio: this.aspectRatio,
        viewMode: this.viewMode,
        dragMode: this.dragMode,
        minContainerWidth: this.minContainerWidth,
        minContainerHeight: this.minContainerHeight,
        zoomOnWheel: false,
        zoomOnTouch: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        autoCropArea: this.autoCropArea,
        guides: this.guides,
        highlight: false,
        ready() {
          // Occupy preview elements
          let clone = this.cloneNode();
          clone.className = '';
          clone.style.cssText = (
            'display: block;' +
            'width: 100%;' +
            'min-width: 0;' +
            'min-height: 0;' +
            'max-width: none;' +
            'max-height: none;'
          )

          _this.each(previews, function(elem) {
            elem.appendChild(clone.cloneNode());
          });
          let t = setTimeout(() => {
            _this.zoomSlider = 0.1
            _this.imgCropper.zoomTo(0.1)
            let CROPPER_DATA = this.cropper.getImageData()
            _this.isMinimalSizeAtLoad = CROPPER_DATA.naturalWidth <= _this.minWidth || CROPPER_DATA.naturalHeight <= (_this.minHeight / _this.aspectRatio)
            _this.inValidImage = _this.minWidth > CROPPER_DATA.naturalWidth || (_this.minHeight / _this.aspectRatio) > CROPPER_DATA.naturalHeight //_this.minHeight > CROPPER_DATA.naturalHeight
            clearTimeout(t)
          }, 0)
          _this.previewReady = true
          _this.isLoading = false
        },

        crop(event) {
          // When preview is ready update preview elemens when
          // crop data is updated.
          if (!_this.previewReady) {
            return;
          }

          var data = event.detail
          var cropper = this.cropper;
          var imageData = cropper.getImageData();
          var previewAspectRatio = this.aspectRatio;

          _this.each(previews, function(elem) {
            var previewImage = elem.getElementsByTagName('img').item(0);
            var previewWidth = elem.offsetWidth;
            var previewHeight = previewWidth / previewAspectRatio;
            var imageScaledRatio = data.width / previewWidth;

            elem.style.height = previewHeight + 'px';
            previewImage.style.width = imageData.naturalWidth / imageScaledRatio + 'px';
            previewImage.style.height = imageData.naturalHeight / imageScaledRatio + 'px';
            previewImage.style.marginLeft = -data.x / imageScaledRatio + 'px';
            previewImage.style.marginTop = -data.y / imageScaledRatio + 'px';
          });
        },
      });
    },

    /**
     * Loop method with used to indentify the preview containers
     */
    each(arr, callback) {
      if (!arr.length) { return }

      for (let i = 0; i < arr.length; i++) {
        callback.call(arr, arr[i], i, arr);
      }

      return arr;
    },

    /**
     * Get cropped image base64 data
     */
    getCroppedCanvas() {
      if (this.imgCropper === null) { return null }
      return this.imgCropper.getCroppedCanvas({
        width: this.width || this.maxWidth,
        height: this.height || this.maxHeight,
        minWidth: this.minWidth,
        minHeight: this.minHeight,
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        fillColor: '#fff',
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high',
      }).toDataURL('image/jpeg')
    },

    /**
     * Rerender image cropper with new chosen image
     */
    onFileChanged(event) {
      const _this = this
      const file = event.target.files[0]

      // if there's no file selected (user cancels) return 
      if (!file) {
        return
      }

      this.inValidImage = false
      this.isMinimalSizeAtLoad = false

      // Check if file is a image
      this.isWrongFileType = false
      if (this.allowedFileTypes.indexOf(event.target.files[0].type.toLowerCase()) === -1) {
        this.isWrongFileType = true
        return
      }

      this.isLoading = true
      this.newImageChosen = true
      this.$emit('callbackImageChosen', true)

      const image = new Image()

      // Clear old blob
      this.imageURL && URL.revokeObjectURL(this.imageURL)
      this.imageURL = URL.createObjectURL(file)
      image.src = this.imageURL

      image.onload = () => {
        let resizedImage = _this.resizeImage(image)

        if (!resizedImage) {
          // No resize needed
          resizedImage = image.src
        }
        else {
          // Source image is resized
          resizedImage = resizedImage
        }

        let t = setTimeout(function() {
          _this.renderCropper(resizedImage);
          resizedImage = ''
          clearTimeout(t)
        }, 10)
      }
    },

    /**
     * Emit cropped image and close modal
     */
    getNewPhoto() {
      // Clear image in memory
      this.imageURL && URL.revokeObjectURL(this.imageURL)

      return this.getCroppedCanvas()
    },

    /**
     * Cropper controls
     */
    cropperZoomTo(evt) {
      const CONTAINER_DATA = this.imgCropper.getCropBoxData()

      if (evt.target.value > 0.8) { return }

      this.imgCropper.zoomTo(evt.target.value, {
        x: CONTAINER_DATA.width / 1.5,
        y: CONTAINER_DATA.height / 1.5,
      })
    },

    /**
     * Resize original image if needed
     */
    resizeImage(img) {
      const MAX_WITH = this.maxWidth
      const MAX_HEIGHT = this.maxHeight
      let isResized = false
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      // calculate the width and height, constraining the proportions
      if (width > height) {
        if (width > MAX_WITH) {
          height = Math.round(height *= MAX_WITH / width);
          width = MAX_WITH;

          isResized = true
        }
      } else {
        if (height > MAX_HEIGHT) {
          width = Math.round(width *= MAX_HEIGHT / height);
          height = MAX_HEIGHT;

          isResized = true
        }
      }

      if (isResized) {
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL("image/jpeg", 1);
      }

      return false
    },

    restoreImage() {
      this.reset()
      EventBus.$emit('showToast', {
        type: 'info',
        message: 'Previous image is restored',
      })
    },

    reset() {
      this.newImageChosen = false
      this.previewReady = false
      this.isMinimalSizeAtLoad = false
      this.inValidImage = false
      this.isWrongFileType = false
      this.zoomSlider = 0.1

      const image = document.getElementById('image')
      image.src = this.originalImage

      this.$emit('callbackImageChosen', false)

      // Destroy cropperjs instance
      this.imgCropper && this.imgCropper.destroy()
      // Destroy image blob
      this.imageURL && URL.revokeObjectURL(this.imageURL)
      // Set original image
      this.imageURL = this.originalImage || '/img/noImage.png'

      const previews = this.previewContainer ? document.querySelectorAll(this.previewContainer) : []
      this.each(previews, function(elem) {
        while (elem.firstChild) {
          elem.removeChild(elem.firstChild)
        }
      })
    }
  },
}
</script>

<style lang="sass" scoped>
.wrapper-imageCropper
  display: flex
  flex-direction: column

  .originalImage
    object-fit: cover

  @media screen and (max-width: 400px)
    .file-icon
      display: none

    .img-container-wrapper
      flex-shrink: 1
      transform: scale(0.8)

  .img-container-wrapper
    position: relative
    .loader-wrapper
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      z-index: 1

      .custom-loader
        position: relative
        z-index: 1
      &::after
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        content: ""
        background-color: #FFFFFF
        opacity: .6

  .img-container
    position: relative
    display: inline-flex
    align-items: center
    // background: #ddd
    overflow: hidden
    flex-shrink: 0
    width: 100%
    background-color: #ebebeb

    ::v-deep img
      // display: block
      // max-width: 100%
      // height: auto
      background-color: transparent !important

    &.is-rounded
      border-radius: 50%

  .slider
    width: 100%

::v-deep .cropper-container
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
</style>